import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

// Images
// import KsoeLogo from "../images/ksoe-logo.png"
// import BlueLabel from "../images/logo-blue.png"
// import KSVLogo from "../images/ksv.png"
import ManagementModelImg from "../images/management-model.png"

const Steuerungsmodell = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/governance-model")
    }
  }, [])

  return (
    <React.Fragment>
      <PageTitle title={"Steuerungsmodell"} />
      <Layout>
        <div className="management-model-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Steuerungsmodell</a>
              </li>
            </ul>
          </div>

          <div className="managment-model">
            <h1>Steuerungsmodell</h1>
            {/* <div className="managment-model__wrapper">
              <div className="page__flex">
                <div className="managment-model__box"></div>
                <div className="managment-model__box">
                  <div className="managment-model__box--item">
                    <div className="managment-model__box--block">
                      <div className="name">Beteiligte Unternehmen</div>
                      <div className="managment-model__box--image">
                        <a
                          href="https://kuratorium-sicheres-oesterreich.at/"
                          className="image"
                          target="_blank"
                        >
                          <img src={KsoeLogo} className="mw-100"></img>
                        </a>
                        <div className="image">
                          <img src={BlueLabel} className="mw-100"></img>
                        </div>
                      </div>
                      <div className="desc">
                        <img src={KSVLogo} className="mw-100"></img>
                      </div>
                    </div>
                    <div className="managment-model__box--desc second">
                      <div className="item item__down">
                        Leitungsorgane der beteiligten Unternehmen Vereine
                        entsenden je einen Vertreter
                      </div>
                      <div className="item item__up">Bericht</div>
                    </div>
                  </div>
                  <div className="managment-model__box--item">
                    <div className="managment-model__box--main">
                      <div className="name">
                        Unternehmen der kritischen Infrastruktur:
                      </div>
                      <ul>
                        <li>Banken</li>
                        <li>Gesundheit</li>
                        <li>Energie</li>
                        <li>Verkehr</li>
                        <li>Finanzmarktinfrastruktur</li>
                        <li>Digitale Infrastruktur</li>
                        <li>Trinkwasser</li>
                        <li>Öffentliche Verwaltung</li>
                      </ul>
                    </div>
                    <div className="managment-model__box--desc">
                      <div className="item item__down">
                        Je ein gewählter Vertreter je Sektor
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="managment-model__block">
                <div className="page__flex">
                  <div className="managment-model__block--item">
                    <div className="managment-model__block--box">
                      <div className="name">Strategisch</div>
                      <div className="text">(CRR-/Label-Vergabe)</div>
                    </div>
                    <div className="managment-model__block--box">
                      <div className="name">Operativ</div>
                      <div className="text">(CRR-/Label-Vergabe)</div>
                    </div>
                  </div>
                  <div className="managment-model__block--item">
                    <div className="managment-model__block--blue">
                      <div className="name">Cyber Risk Management Board</div>
                      <div className="text">
                        <p>
                          Erarbeitet Vorschläge für das CRR-Schema und das
                          darauf basierende Cyber Risk Label
                        </p>
                      </div>
                      <div className="desc">
                        <p>
                          Eskalationsinstanz bei Unklarheiten bzgl. Rating und
                          Qualifikation für Label
                        </p>
                        <p>Prüfung nach Aussetzungen des Ratings / Labels</p>
                      </div>
                    </div>
                  </div>
                  <div className="managment-model__block--item">
                    <div className="managment-model__block--inner">
                      <div className="item item__right">Schlägt vor</div>
                      <div className="item item__left">Beschließt</div>
                    </div>
                    <div className="managment-model__block--inner">
                      <div className="item item__right">Jährlicher Bericht</div>
                    </div>
                  </div>
                  <div className="managment-model__block--item">
                    <div className="managment-model__block--gold">
                      <div className="name">Cyber Risk Advisory Board</div>
                      <div className="text">
                        <p>Definition Anforderungen</p>
                        <p>
                          Beschluss des CRR-Schemas und des darauf basierenden
                          Cyber Risk Labels
                        </p>
                      </div>
                      <div className="desc">
                        <p>Kontrollinstanz</p>
                        <p>Im Bedarfsfall Korrekturen am Schema</p>
                      </div>
                    </div>
                  </div>
                  <div className="managment-model__block--item">
                    <div className="managment-model__block--desc">
                      <span>powered by:</span>
                      <a
                        href="https://kuratorium-sicheres-oesterreich.at/"
                        target="_blank"
                      >
                        <img
                          src={KsoeLogo}
                          className="mw=100"
                          style={{ maxWidth: "92px" }}
                        ></img>
                      </a>
                    </div>
                    <div className="managment-model__block--desc"></div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="managment-model__text-wrapper">
              <div className="text">
                <p>
                  Die Steuerung des Cyber Risk Rating Schemas liegt in der Hand
                  des vom KSÖ betriebenen Cyber Risk Advisory Boards, welches
                  sich aus Vertretern der kritischen Sektoren gemäß NIS-Gesetz
                  zusammensetzt. Die Mitglieder sind leitende
                  Sicherheitsverantwortliche bei großen Unternehmen der
                  kritischen NIS-Sektoren (Banken, Energiesektor, Gesundheit,
                  Digitale Dienste, etc.). Weiters sind Vertreter der
                  zuständigen NIS-Behörde im Cyber Risk Advisory Board
                  vertreten. Auf diese Weise ist sichergestellt, dass die
                  Anforderungen des Cyber Risk Ratings nicht nur auf dem
                  aktuellen Stand der Technik sind, sondern auch den
                  Anforderungen der Sicherheitsabteilungen großer Unternehmen
                  sowie auch der zuständigen Behörden genügen.
                </p>
                <p>
                  Das Schema unterliegt einer laufenden Überprüfung und
                  Weiterentwicklung, um den sich ändernden Anforderungen des
                  Cybersicherheitsumfelds Rechnung zu tragen.
                </p>
              </div>
            </div>

            <div className="zoom__wrapper">
              <div
                className="zoom__img__wrapper"
                onClick={() => setIsOpen(!isOpen)}
              >
                <svg
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_213_11781)">
                    <path
                      d="M20.8511 19.6593C22.6383 17.5741 23.8298 14.794 23.8298 11.8153C23.8298 5.26209 18.4681 -0.0996094 11.9149 -0.0996094C5.3617 -0.0996094 0 5.36138 0 11.9146C0 18.4678 5.3617 23.8295 11.9149 23.8295C15.1915 23.8295 18.0709 22.5387 20.2553 20.4536L27.8014 27.9997L28.4965 27.3046L20.8511 19.6593ZM11.9149 22.8366C5.85816 22.8366 0.992908 17.872 0.992908 11.9146C0.992908 5.95713 5.85816 0.992589 11.9149 0.992589C17.9716 0.992589 22.8369 5.85784 22.8369 11.9146C22.8369 17.9713 17.8723 22.8366 11.9149 22.8366Z"
                      fill="white"
                    />
                    <path
                      d="M17.1773 11.4183V12.4112H12.4113V17.1772H11.4184V12.4112H6.65247V11.4183H11.4184V6.65234H12.4113V11.4183H17.1773Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_213_11781">
                      <rect width="28.3972" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>

            <div className="managment-model__wrapper">
              <input
                type="checkbox"
                id="zoomCheck"
                checked={isOpen}
                onChange={() => {}}
              ></input>
              <label htmlFor="zoomCheck">
                <img
                  src={ManagementModelImg}
                  alt="Management Model"
                  title="Management Model"
                  className="mw-100"
                  onClick={() => setIsOpen(!isOpen)}
                ></img>
              </label>
            </div>

            <div className="asked-question__block--contact">
              <div>
                <h2>
                  <b>
                    Wollen Sie mehr über Cyber Trust Austria erfahren?
                    Kontaktieren Sie uns.
                  </b>
                </h2>
              </div>
              <div>
                <div>
                  <a
                    style={{
                      // border: "4px solid",
                      padding: "10px 10px",
                      width: 275,
                      textDecoration: "none",
                    }}
                    className="white-button-large page__btn page__btn--blue-border"
                    href="https://calendly.com/cyber-trust/30min"
                    target="_blank"
                  >
                    Zur kostenlosen Beratung
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default Steuerungsmodell
